import InvestCard from "../InvestCard/InvestCard";
import DashboardItem from "../DashboardItem/DashboardItem";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { invests } from "../../static/consts";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import CallService from "../../services/CallService";
import Carousel from "react-simply-carousel";
import InvestSelectModal from "../Modals/InvestSelectModal";
import Modal from "../Modals/Modal";

const MainContent = () => {
  const [dashboardValues, setDashboardValues] = useState(null);
  const { t } = useTranslation();
  const { firstName, token } = localStorage;
  const [activeSlide, setActiveSlide] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const data = await CallService.GetDashboardData();
      data && setDashboardValues(data);
    })();
  }, []);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      {firstName && token ? (
        <>
          <Box className="mb-5" sx={{ display: { xs: "block", sm: "none" } }}>
            <Carousel
              updateOnItemClick
              containerProps={{
                style: {
                  display: "block",
                  width: "100%",
                  justifyContent: "space-between",
                },
              }}
              infinite={false}
              activeSlideIndex={activeSlide}
              activeSlideProps={{
                style: {
                  background: "blue",
                },
              }}
              onRequestChange={setActiveSlide}
              speed={500}
              delay={2000}
            >
              <DashboardItem
                value={dashboardValues?.deposits.toLocaleString()}
                text={t("you_invested")}
                classAlign="mr-3 pl-4 py-2 bg-white rounded-lg text-left cursor-pointer"
                isMobile={true}
              />
              <DashboardItem
                value={dashboardValues?.profit.toLocaleString()}
                text={t("profit")}
                classAlign="mr-3 pl-4 py-2 bg-white rounded-lg text-left cursor-pointer"
                isMobile={true}
              />
              <DashboardItem
                customEndText="%"
                value={dashboardValues?.percent_change.toLocaleString()}
                text={t("percent_change")}
                classAlign="mr-3 pl-4 py-2 bg-white rounded-lg text-left cursor-pointer"
                isMobile={true}
              />
            </Carousel>
          </Box>
          <Box
            className="flex justify-end mb-10 mr-12"
            sx={{ display: { xs: "none", sm: "flex" } }}
          >
            <div className="flex">
              <DashboardItem
                value={dashboardValues?.deposits.toLocaleString()}
                text={t("you_invested")}
                classAlign="pl-12 text-right"
              />
              <DashboardItem
                value={dashboardValues?.profit.toLocaleString()}
                text={t("profit")}
                classAlign="pl-12 text-right"
              />
              <DashboardItem
                customEndText="%"
                value={dashboardValues?.percent_change.toLocaleString()}
                text={t("percent_change")}
                classAlign="pl-12 text-right"
              />
            </div>
          </Box>
          <div className="flex justify-center">
            <div className="flex flex-col items-start">
              <button
                onClick={openModal}
                className="ml-8 mb-2 md:w-80 shadow-shadow200 bg-white px-3 text-blue rounded text-center outline-none cursor-pointer h-12 font-bold flex items-center"
              >
                {t("invest_modal_transfer_title")}
                <span
                  style={{
                    transform: "rotate(180deg)",
                    display: "inline-block",
                    marginLeft: 6,
                  }}
                >
                  &#8963;
                </span>
              </button>
              <Modal show={isOpen} onClose={closeModal}>
                <InvestSelectModal />
              </Modal>

              <div className="flex justify-center flex-wrap">
                {invests.map((item) => (
                  <div className="w-96" key={item.name}>
                    <InvestCard
                      item={item}
                      name={item.name}
                      image={item.image}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Navigate to="/profile" />
      )}
    </>
  );
};

export default MainContent;
